img,
video {
    max-width: 100%;
}

body {
    font-family: 'Montserrat', sans-serif;
}

a {
    text-decoration: none;
}

p {
    font-size: 16px;
    font-weight: 500;
}

.form-check-input:focus,
.form-select:focus {
    box-shadow: none;
}

.form-select {
    cursor: pointer;
}

.red-color {
    color: #ff0000;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-bottom: 1px solid #000;
    transition: all .2s linear;
    /* padding: 10px 0 9px; */
}

header.scroll-fixed {
    position: sticky;
    top: 0;
    background: #fff;
    box-shadow: 0px 1px 15px #ccc;
}

header .navbar-toggler {
    padding: 0;
    border: 0;
}

header .navbar-toggler:focus {
    box-shadow: none;
}

header .navbar-brand {
    max-width: 45px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
}

/* 
header .navbar-brand .hover-mob-logo {
    display: none;
}

header.scroll-fixed .navbar-brand .web-logo {
    display: none;
}

header.scroll-fixed .navbar-brand .hover-mob-logo {
    display: block;
} */

header .navbar-expand-md .navbar-nav .nav-link,
header .nav-link {
    padding: 15px 20px;
}

header .nav-link {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid transparent;
    text-align: center;
    position: relative;
}

header .nav-link:hover,
header .nav-link.active {
    color: #a3865b;
    border-color: #a3865b;
}

header .nav-link i {
    margin-right: 3px;
}

header .dropdown:hover .dropdown-menu {
    display: block;
}

header .right-btn {
    display: flex;
    margin-left: auto;
}

header .right-btn .nav-link i {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: contain;
}

header .right-btn .nav-link i.search {
    background-image: url('../images/icon/search.png');
}

header .right-btn .nav-link i.bag {
    background-image: url('../images/icon/bag.png');
}

header .right-btn .nav-link i.user {
    background-image: url('../images/icon/user.png');
}

header .right-btn .nav-item+.nav-item {
    border-left: 1px solid #000;
}

header.scroll-fixed .right-btn .nav-item+.nav-item {
    border-color: #000;
}

.home-banner {
    position: relative;
}

.banner-video video {
    width: 100%;
    display: block;
    object-fit: cover;
    max-height: 100vh;
}

.banner-content {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.banner-content h1 {
    font-size: 48px;
    font-weight: 700;
    color: #a3865b;
    line-height: 56px;
    margin-bottom: 70px;
    white-space: nowrap;
}

.banner-content a {
    background-color: transparent;
    border: 2px solid #fff;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    padding: 15px 63px 15px 32px;
    transition: all .2s linear;
    text-decoration: none;
    position: relative;
    display: inline-block;
}

.banner-content a::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 2px;
    width: 24px;
    right: 31px;
    top: 30px;
    transition: all .2s linear;
}

.banner-content a::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    right: 32px;
    top: 26px;
    border: 2px solid #fff;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
    transition: all .2s linear;
}

.banner-content a:hover {
    background: #fff;
    color: #000;
}

.banner-content a:hover::after {
    border-color: #000;
}

.banner-content a:hover::before {
    background-color: #000;
}

.banner-content a:hover::before {
    right: 25px;
    width: 30px;
}

.banner-content a:hover::after {
    right: 24px;
}

.section {
    padding: 50px 0;
}

section h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 36px;
    margin-bottom: 25px;
    font-weight: 700;
    /* font-family: 'Space Mono', monospace; */
}

.orange-btn {
    background-color: #a3865b;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 6px 0px;
    border-radius: 25px;
    text-transform: capitalize;
    display: inline-block;
    white-space: nowrap;
    min-width: 160px;
    padding: 7px 25px;
    font-weight: 600;
    font-size: 18px;
    transition: all 0.2s;
    text-align: center;
}

.orange-btn:hover {
    color: #fff;
    background-color: #a3865b;
    transform: scale(1.02);
}

.orange-cancel-btn {
    background-color: #d7b889;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 6px 0px;
    border-radius: 25px;
    text-transform: capitalize;
    display: inline-block;
    white-space: nowrap;
    min-width: 160px;
    padding: 7px 25px;
    font-weight: 600;
    font-size: 18px;
    transition: all 0.2s;
    text-align: center;
}

.orange-cancel-btn:hover {
    color: #fff;
    background-color: #70470a;
    transform: scale(1.02);
}

.category-section {
    position: relative;
    background: linear-gradient(to top, #ede3e3, transparent);
}


.category-wrap {
    /* background-color: #fff; */
    padding: 0 20px 20px;
}

.category-wrap+.category-wrap {
    margin-top: 20px
}

.category-wrap ul,
.brands-wrap ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
}

.category-wrap ul {
    margin-bottom: 20px;
    row-gap: 5px;
}

.season-category {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.season-category img {
    height: 370px;
    object-fit: cover;
    width: 100%
}

.season-category span {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 100%;
    height: 100%;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    display: flex;
    transition: all 0.2s;
}

.season-category:hover span {
    top: 0;
}

.brands-wrap ul {
    margin: 0 -10px;
}

.category-wrap ul li {
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
}

.category-wrap ul li a {
    color: #000;
}

.brands {
    background-color: #fbfbfb;
}

.brands-wrap ul li:hover {
    transform: scale(1.03);
    transition: all 0.2s;
    box-shadow: -1px -1px 12px #ccc;
}

.brands-wrap ul li {
    padding: 0 10px;
    background: #fff;
    border-radius: 30px 0 30px 0;
    height: 116px;
    width: 200px;
    object-fit: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brands-wrap ul {
    gap: 30px;
}

.brands-wrap ul li img {
    max-height: 100px;
    max-width: 85%;
}

.latest-section .nav {
    justify-content: center;
}

.latest-section .nav-underline .nav-link.active,
.latest-section .nav-underline .nav-link:hover {
    color: #a3865b;
    font-weight: 700;
}

.latest-section .nav-underline .nav-link {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
}

.latest-section .tab-content {
    margin-top: 20px;
}

.latest-slider-inner {
    position: relative;
    overflow: hidden;
}

.latest-slider .slick-slide {
    padding: 0 5px;
}

.latest-slider-inner .content {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    padding: 20px;
    text-align: left;
}

.latest-slider-inner img {
    transition: all 0.3s;
}

.latest-slider-inner:hover img {
    transform: scale(1.03);
}

.latest-slider-inner h3 {
    margin: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 26px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 3s;
}

.latest-slider-inner:hover h3 {
    display: block
}

.latest-slider-inner img {
    height: 500px;
    object-fit: cover;
}

.latest-slider-inner .date-author {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: 5px;
}

.latest-slider .slick-prev {
    left: 15px;
}

.latest-slider .slick-next {
    right: 15px;
}

.latest-slider .slick-arrow {
    width: 50px;
    height: 50px;
    z-index: 1;
}

.latest-slider .slick-arrow:before {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 50 50' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 50 50'%3E%3Cpath d='M37.561 47.293 15.267 25 37.561 2.707l-1.415-1.414L12.439 25l23.707 23.707z' fill='%23ffffff' class='fill-000000'%3E%3C/path%3E%3C/svg%3E");
    height: 50px;
    display: inline-block;
    width: 50px;
    background-size: contain;
    opacity: 0.8;
    content: "";
    background-repeat: no-repeat;
}

.latest-slider .slick-next:before {
    transform: scale(-1);
}

footer {
    background: #000;
    color: #ccc;
    padding: 50px 0;
    font-size: 16px;
}

footer a {
    color: #fff;
    font-weight: 600;
}

footer h2 {
    font-size: 18px;
    margin-bottom: 25px;
    font-weight: 700;
}

footer i {
    font-size: 20px;
}

.footer-social-media {
    display: flex;
    gap: 14px;
    list-style: none;
    margin: 0;
    padding: 0;
}

footer .form-select {
    background-color: transparent;
    border-width: 0 0 1px 0;
    border-radius: 0;
    border-color: #ccc;
    color: #ccc;
    padding: 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    margin-bottom: 20px;
}

.contact-banner {
    text-align: center;
}

.contact-banner h1 {
    font-size: 60px;
    margin-bottom: 20px;
}

.contact-banner p {
    max-width: 1200px;
    margin: 0 auto;
    font-weight: 600;
}

.form-section p {
    color: #333333;

}

.form-section a {
    color: #a3865b;
    font-weight: 600;
}

.form-section .form-label {
    font-size: 16px;
    font-weight: bolder;
    font-family: 'Space Mono', monospace;
    color: #5c5c5c;
}

.form-section .form-control {
    border: 1px solid #c9c9c9;
    border-radius: 0;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 30px;
}

.form-section .form-control:focus {
    border: 1px solid #333;
    box-shadow: none;
}

.form-section form button[type="submit"] {
    width: 100%;
    line-height: 35px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    border-radius: 0;
    background: #fff;
    border: 2px solid #333;
}

.grey-btn {
    background: #a3865b;
    color: #fff;
    font-size: 16px;
    width: 200px;
    border-radius: 20px;
    padding: 10px 0;
    margin: 0 auto;
    font-weight: 600;
    text-transform: capitalize;
}

.form-section form button[type="submit"]:hover {
    background-color: #333;
    color: #fff;

}

.contact-info-section {
    color: #333;
    background: linear-gradient(to bottom, #ede3e3, transparent);
}

.contact-info-section h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 25px;
    font-weight: 700;
}

.contact-info-section a {
    color: #a3865b;
    font-weight: 500;
}

.latest-banner {
    text-align: center;
}

.top-content {
    padding: 30px 0 50px;
}

.latest-banner .top-content h1 {
    margin-bottom: 20px;
    font-size: 60px;
}

.latest-banner .top-content p {
    max-width: 1200px;
    margin: 0 auto;
    font-weight: 600;
}

.about-banner {
    background: url(../images/aboutus-imgnew.jpg) center/cover;
    padding: 200px 0;
    text-align: center;
}

.about-banner h1 {
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 36px;
    max-width: 600px;
    margin: 0 auto;
    line-height: 60px;
    font-family: 'Space Mono', monospace;
}

.section.about-info {
    padding: 75px 0;
    background-image: linear-gradient(to bottom, #ede3e3, transparent);
}

.about-info h2 {
    max-width: 800px;
    margin: 0 auto 30px;
}

.about-info p {
    max-width: 1300px;
    text-align: center;
    margin: 0 auto;
}

.feature {
    background: #fdfdfd;
}

.feature .inner,
.team .inner {
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 6px #d9d9d9;
    border-radius: 10px;
    height: 100%;
    padding: 40px 20px;
}

.feature .inner img {
    max-width: 100px;
    margin-bottom: 20px;
}

.feature .inner p {
    margin: 0;
}

.team {
    background: linear-gradient(to top, #ede3e3, transparent);
}

.team .top-para {
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
}

.team .inner h3 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
}

.team .inner span {
    font-family: 'Space Mono', monospace;
    font-size: 72px;
}

.dashboard-outer {
    height: 100vh;
}

.login-wrap {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background: #ccc; */
}

.login-wrap .logo {
    margin-bottom: 15px;
}

.login-wrap p {
    font-size: 20px;
}

.login-wrap a {
    background: #000;
    color: #fff;
    padding: 0 25px;
    display: inline-block;
    margin: 10px;
    min-width: 150px;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
}

.login-header {
    flex-wrap: wrap;
    background: #0C234B;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
}

.dashboard-wrap {
    height: 100%;
    overflow: auto;
}

.dashboard-wrap .heading-wrap {
    padding: 45px 30px;
    background-color: #a3865b;
}

.dashboard-wrap h1 {
    font-weight: 600;
    font-size: 40px;
    color: #fff;
    margin-bottom: 30px;
}

.dashboard-wrap h1 .name {
    font-weight: 700;
}

.dashboard-wrap form {
    padding: 18px 30px;
}

.login-header .navbar-brand img {
    height: 30px;
}

.login-header .header-right {
    margin-left: auto;
}

.profile-photo {
    background: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
}

.dashboard-outer-inner {
    display: flex;
    height: calc(100% - 55px);
}

.dashboard-outer-inner .left-fixed-menu {
    background: #FFFFFF;
    box-shadow: 6px 0px 20px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 20px;
    overflow: auto;
    flex-shrink: 0;
}

.dashboard-outer-inner .left-fixed-menu::-webkit-scrollbar {
    display: none;
}

.dashboard-outer-inner .right-column {
    flex-grow: 1;
}

.left-fixed-menu .options h3 {
    font-size: 18px;
    text-transform: capitalize;
}

.left-fixed-menu .options {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
}

.left-fixed-menu .options a {
    display: block;
    color: #7f7f7f;
    font-size: 14px;
    padding: 5px 0;
}

.dashboard-wrap h2 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
}

.dashboard-wrap form .form-control[readonly] {
    background: transparent;
    border: 0;
}

.dashboard-wrap form>div {
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0;
}

.dashboard-wrap form .form-label,
.dashboard-wrap form .form-control {
    font-size: 14px;
}

.login-details {
    display: flex;
    align-items: center;
    height: 100vh;
    flex-wrap: wrap;
}

.login-details .logo-div {
    width: 49%;
    background: #F8FBFF;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.login-details .form-div {
    padding: 20px;
    flex-grow: 1;
    color: #081732;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
}

.login-details .form-wrap h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 6px;
    text-align: center;
}

.login-details .form-wrap .note {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 45px;
    text-align: center;
}

.login-details .form-wrap.enter-otp-wrap .note {
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 60px;
}

.login-details .form-wrap .note .bold-600 {
    font-weight: 600;
}

.login-details .form-wrap {
    width: 360px;
    margin: 0 auto;
    padding-top: 10px;
    max-width: 100%;
}

.login-details .or-txt {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 42px 0 25px;
    display: block;
    text-align: center;
}

.login-details .form-wrap .form-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.5px;
    margin-bottom: 6px;
}

.login-details .form-wrap .form-control {
    display: flex;
    border: 1px solid #B0B0B0;
    border-radius: 5px;
    padding: 14px 20px;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #000000;
}


.blue-btn {
    height: 46px;
    background: #005DFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 12px;
    color: #FFFFFF;
    width: 100%;
}

.blue-btn.btn.disabled {
    background-color: #005DFF;
    border-color: #005DFF;
    color: #fff;
}

.blue-btn:hover,
.blue-btn:active {
    background-color: #005DFF;
    color: #FFFFFF;
}

.login-details .login-logo {
    max-width: 150px;
}

.wishlist-wrap {
    padding: 100px 0;
}

.wishlist-wrap h2 {
    font-size: 18px;
    font-weight: 700;
    color: #282c3f;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 30px;
}

.wishlist-wrap h2 span {
    font-weight: 400;
}

.cart-items-list {
    outline: 1px solid #e9e9eb;
}

.cart-items-list+.cart-items-list {
    margin-top: 10px;
}

.wishlist-column {
    background-color: #fff;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
    padding: 0 0 14px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 210px;
    flex: 1 0 210px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.item-image-div {
    position: relative;
}

.item-image-div .remove-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #686363;
    cursor: pointer;
}

.item-details {
    font-size: 14px;
    color: #696b79;
    padding: 10px;
    border-bottom: 1px solid #e9e9eb;
}

.item-details-label {
    font-size: 16px;
    color: #282c3f;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}

.item-details-pricing {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    /* text-align: center; */
}

span.item-details-bold-font {
    font-weight: 700;
    padding: 0 3px 0 0;
    font-size: 16px;
    color: #282c3f;
}

span.item-details-strike {
    text-decoration: line-through;
    opacity: .7;
    font-size: 12px;
    padding: 0 3px;
    color: #282c3f;
}

span.item-details-discount-percent {
    color: #a3865b;
    padding: 0 3px;
    font-size: 12px;
    font-weight: 700;
}

.itemcard-actionDiv {
    text-align: center;
    font-size: 14px;
    padding-top: 14px;
    border-top: 1px solid #e9e9eb;
}

.itemcard-actionDiv a {
    color: #a3865b !important;
    font-weight: 700;
    cursor: pointer;
}

.commerce-product-container {
    max-width: 1470px;
    padding: 0 20px;
    margin: 0 auto;
}

.product-top-option {
    display: flex;
    align-items: center;
    padding-bottom: 23px;
    border-bottom: 1px solid #3A3A3A;
    margin-bottom: 35px;
}

.product-top-option .form-control[type="search"] {
    border-radius: 6px;
    background: url('../images/search.webp') left 15px center no-repeat #DFE8F6;
    width: 332px;
    height: 40px;
    color: #747474;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding-left: 40px;
    border: 0;
}

.product-top-option .sort {
    border-radius: 6px;
    background: #DFE8F6;
    width: 240px;
    height: 40px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.product-top-option .sort .form-select {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    border: 0;
    width: auto;
    padding: 0;
    height: auto;
    flex-grow: 1;
}

.product-top-option .sort,
.product-top-option .sort .form-select {
    color: #3A3A3A;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
}

.commerce-product h2 {
    color: #3A3A3A;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.product-filter-list {
    border-radius: 3px;
    border: 1px solid #D4D4D4;
    background: #FFF;
}

.product-filter-list a {
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
}

.product-filter-list>div {
    padding: 20px;
    user-select: none;
}

.product-filter-list>div:hover {
    background: #EDF3FB;
}

.product-filter-list>div+div {
    border-top: 1px solid #D4D4D4;
}

.product-filter-list ul {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
}

.product-filter-list ul li {
    margin-bottom: 20px;
}

.product-filter-list label {
    color: #3A3A3A;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
}

.product-filter-list .form-check-input {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 2px solid #3A3A3A;
}

.product-filter-list .form-check-input:checked {
    background-color: #a3865b;
    border-color: #a3865b;
}

.product-filter-list .form-check-input:checked[type=checkbox]+label {
    color: #a3865b;
}

.product-filter-list a:after {
    float: right;
    margin-top: -3px;
}

.product-filter-list a[aria-expanded="true"]:after {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><rect x="0.5" y="0.5" width="29" height="29" rx="1.5" fill="white" stroke="%23D4D4D4"/><line x1="10" y1="15" x2="20" y2="15" stroke="%233A3A3A" /></svg>')
}

.product-filter-list a[aria-expanded="false"]:after {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><rect x="0.5" y="0.5" width="29" height="29" rx="1.5" fill="white" stroke="%23D4D4D4"/><line x1="10" y1="15" x2="20" y2="15" stroke="%233A3A3A" /><line x1="15" y1="10" x2="15" y2="20" stroke="%233A3A3A" /></svg>');
}

.product-filter-list .price-range .input-group-wrap {
    gap: 10px;
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
}

.product-filter-list .price-range .input-group-wrap button {
    border-radius: 4px;
    border: 2px solid #a3865b;
    background: #a3865b;
    width: 52px;
    height: 40px;
    color: #FFFEFF;
    text-align: center;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 8px 13px;
}

.product-filter-list .price-range .input-group-wrap .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border-radius: 6px;
    background: #DFE8F6;
    color: #8D95A3;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    border: 0;
    height: 40px;
    padding: 11px 15px;
}

.product-list-row {
    row-gap: 30px;
}

.login-btns,
.login-btns:hover {
    color: #000;
    border: 2px solid #000;
    border-radius: 8px;
    background-color: #fff;
    font-size: 16px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}

.login-btns img {
    margin-right: 10px;
    vertical-align: middle;
}

.cart-right-col .subtotal {
    padding: 20px;
}

.cart-right-col .subtotal>div {
    color: #3A3A3A;
    font-size: 14px;
    font-weight: 600;
    padding-top: 15px;
}

.cart-right-col .subtotal>div:first-child {
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #979797;
    padding: 0 0 15px;
}

.cart-right-col .total {
    border-radius: 0px 0px 5px 5px;
    background: #cfcac2;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
}

.cart-right-col .info {
    color: #181818;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.cart-subtotal {
    color: #0C234B;
    font-size: 20px;
    font-weight: 700;
    padding: 20px;
    text-align: right;
}

.cart-subtotal span {
    margin-left: 48px;
}

.o-btn,
.o-btn:hover {
    border-radius: 4px;
    min-width: 160px;
    height: 40px;
    line-height: 40px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    color: #FFFFFF;
    background: #a3865b;
    border: 0;
}

.cart-row,
.cart-product,
.cart-right-col .total,
.cart-right-col .subtotal>div {
    display: flex;
}

.cart-row {
    gap: 40px;
}

.cart-right-col {
    flex: 0 0 375px;
}

.cart-right-col .inner {
    border-radius: 6px;
    border: 1px solid #eaeaec;
    background: #FFF;
    width: 100%;
    margin-bottom: 20px;
}

.cart-section {
    padding: 80px 0 50px;
}

.cart-left-col {
    flex-grow: 1;
}

.address-strip {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #eaeaec;
    margin-bottom: 8px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
    row-gap: 10px;
}

.address-strip-title {
    font-size: 14px;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.address-strip-highlight {
    font-size: 14px;
    font-weight: 700;
}

.address-strip-subText {
    font-size: 12px;
    line-height: 15px;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.change-add,
.change-add:hover {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    color: #a3865b;
    border-color: #a3865b;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
}

.bulk-action {
    line-height: 15px;
    font-weight: 700;
    font-size: 12px;
    color: #282c3f;
}

.bulk-action-button {
    padding: 10px;
}

.bulk-action-button .btn {
    color: #535766;
    height: 32px;
    font-size: 12px;
    text-decoration: none;
    border: none;
    background: none;
    text-transform: uppercase;
    font-weight: 700;
}

.bulk-action .form-select {
    font-size: 12px;
}

.bulk-action .table td {
    vertical-align: middle;
}

.bulkActionStrip-selectionIcon {
    margin-right: 10px;
}

.bulkActionStrip-activeIcon {
    fill: #a3865b;
    background: #fff;
    border-radius: 2px;
}

.cart-items-list .wishlist-column {
    display: flex;
    padding: 0;
    position: relative;
}

.cart-items-list .wishlist-column .item-image-div {
    width: 150px;
}

.cart-items-list .wishlist-column .item-image-div img {
    cursor: pointer;
}

.cart-items-list .item-details-label {
    font-weight: 600;
    text-align: left;
    font-size: 14px;
}

.remove-cart {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
}

.cart-items-list .item-actions {
    flex-grow: 1;
}

.cart-items-list .item-details-pricing {
    text-align: left;
}

.item-detail-sub-label {
    font-size: 14px;
    margin: 0;
}

.item-detail-sold {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    color: #94969f;
}

.cart-items-list span.item-details-bold-font {
    font-size: 14px;
}

.cart-items-list .item-details {
    border: 0;
}

.return-period,
.delivery-period {
    color: #282c3f;
    font-size: 12px;
}

.return-period svg,
.delivery-period svg {
    margin-right: 5px;
}

.delivery-period svg {
    fill: #008000;
}

.return-period span,
.delivery-period span {
    font-weight: 800;
}

.cart-section .container {
    max-width: 980px;
}

.add-from-wishlist {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: #fff;
    position: relative;
    font-weight: 700;
    padding: 16px 12px 16px 16px;
    margin-top: 24px;
    border: 1px solid #eaeaec;
    border-radius: 4px;
    font-size: 13px;
    color: #282c3f;
}

.add-from-wishlist svg {
    margin-right: 10px;
}

.add-from-wishlist .addToWishlist-base-wishlistChevron {
    margin-left: auto;
}

.subscription-section.form-section {
    padding: 100px 0;
}

.sign-details {
    background: url('../images/sign.jpeg') center center/cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.sign-wrap {
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    width: 600px;
    max-width: 100%;
    padding: 40px;
    border-radius: 40px;
    box-shadow: 0 0 9px 1px #414141;
    text-align: center;
}

.sign-wrap .logo {
    width: 60px;
    margin-bottom: 10px;
}

.sign-wrap h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.sign-wrap .form-control {
    background: transparent;
    color: #fff;
    box-shadow: 0 0 3px 1px #979797;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 30px;
    padding: 15px 30px;
    font-size: 15px;
    margin-bottom: 30px;
    text-align: left;
}

.sign-wrap .form-control::placeholder {
    color: #fff;
    opacity: 0.8;
}

.sign-wrap button[type="submit"] {
    background: #a3865b;
    color: #fff;
    font-size: 16px;
    width: 200px;
    border-radius: 20px;
    padding: 10px 0;
    margin: 0 auto;
    font-weight: 600;
    text-transform: capitalize;
}

.sign-wrap .or-txt {
    margin: 30px 0 20px;
    font-size: 15px;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
}

.sign-wrap .social-btns img {
    width: 50px;
    cursor: pointer;
}

.social-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.member-links a {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
}

.member-links a b {
    font-weight: 600;
}

.member-links {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 25px;
    padding: 0 30px;
    row-gap: 0;
}

.blog-wrapper img {
    width: 100%;
}

.blog-wrapper p {
    font-size: 14px;
}

.blog-wrapper h4 {
    font-size: 18px;
    font-weight: 700;
}

.invalid-feedback {
    text-align: left;
    position: absolute;
    /* top: 55px; */
    font-size: 13px;
}

.product-list-wrap {
    padding-bottom: 50px;
}

.product-list-wrap>img {
    height: 300px;
    object-fit: cover;
    opacity: 0.8;
}

.item-image-div .fa-heart {
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #a3865b;
}

.product-detail-wrap {
    padding-top: 100px;
}

.product-img-nav img {
    cursor: pointer;
    opacity: 0.5;
}

.product-img-nav .slick-track {
    height: auto !important;
}

.product-img-nav .slick-current img {
    opacity: 1;
}

.product-detail-info {
    border: 1px solid #000;
    padding: 25px;
    font-size: 13px;
    letter-spacing: 0.8px;
    font-weight: 300;
}

.product-detail-info-header-name,
.product-data h2,
.product-data .anchor-heding {
    width:90%;
    margin: 0;
    line-height: 20px;
    font-size: 13px;
    color: #000;
    font-weight: bold;
}

.product-data .anchor-heding {
    text-decoration: underline;
}

.price-messages {
    font-size: 11px;
}

.product-detail-description {
    font-size: 13px;
    margin: 10px 0;
}

.product-detail-actions-actions {
    list-style: none;
    padding: 0;
    margin: 0;
}

.product-detail-actions-actions li .btn {
    font-size: 11px;
    text-transform: uppercase;
    padding: 0;
    text-align: left;
}

.wishlist-btn {
    position: absolute;
    right: 0;
    top: 0;
}

.wishlist-btn i.active {
    color: red
}

.add-wishlist i.active {
    color: red
}

.product-detail-size-actions,
.product-data {
    border: 1px solid #000;
    border-top: 0;
    padding: 25px;
}

.modal .product-data {
    border-top: 1px solid;
}

.product-data h2 {
    text-align: left;
    margin-bottom: 10px;
}

.product-data .form-check {
    display: flex;
    gap: 10px;
}

.product-data .form-check-label {
    font-size: 14px;
}

.product-data .form-check-label span {
    font-weight: 600;
}

.product-data .counter,
.bulk-action .counter {
    display: inline-flex;
    gap: 2px;
    /* align-items: center; */
    vertical-align: super;
}

.product-data .counter a,
.bulk-action .counter a {
    height: 30px;
    display: inline-block;
    width: 30px;
    text-align: center;
    /* line-height: 28px;
    border: 1px solid; */
    color: #000;
    font-size: 20px;
    cursor: pointer;
}

.product-data .counter a.minus,
.bulk-action .counter a.minus {
    /* background: #efefef;
    border-color: #efefef; */
    color: #ccc;
}

.product-color-extended-name {
    font-size: 11px;
}

.size-selector-size-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 -10px;
    flex-wrap: wrap;
    row-gap: 10px;
}

.size-selector-size-list li {
    padding: 0 10px;
    width: 120px;
    text-align: center;
    cursor: pointer;
}

.size-selector-size-list li>div {
    border: 1px solid #000;
    padding: 5px 0;
}

.size-selector-size-list li.active>div {
    background-color: #000;
    color: #fff;
}

.size-selector-size-list li.not-available>div {
    color: #a5a5a5;
    border-color: #a5a5a5;
    position: relative;
}

.size-selector-size-list li.not-available>div::after {
    content: "";
    background: #a5a5a5;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 15px;
    transform: rotate(15deg);
}

.size-selector-size-list li.not-available {
    pointer-events: none;
}

.product-detail-buttons {
    border: 1px solid #000;
    border-top: 0;
    text-align: center;
}

.product-detail-buttons .btn {
    padding: 10px 0;
    width: 100%;
}

.size-selector-size-messages .btn {
    padding: 0;
    font-size: 11px;
    text-transform: uppercase;
    position: relative;
    padding-right: 11px;
}

.size-selector-size-messages {
    margin-top: 10px;
}

.size-selector-size-messages .btn:first-child::after {
    content: "|";
    position: absolute;
    right: 0;
    top: -1px;
}

.you-may-like {
    padding: 50px 0;
}

.you-may-like h2 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
}

.product-list-wrap h2 {
    font-size: 40px;
    line-height: 1.12;
    font-weight: 300;
    font-style: italic;
    margin: -150px 0 0;
    padding: 35px 0 50px 30px;
    background: #fff;
    position: relative;
    text-align: left;
}

.product-list-wrap h2 strong {
    font-weight: 800;
}

.product-list-wrap .sort .form-select {
    vertical-align: top;
    margin-bottom: 0px;
    text-transform: uppercase;
    width: auto;
    font-size: 13px;
    border: none;
    border-bottom: solid 1px;
    border-color: #ccc;
    border-radius: 0px;
    background-position: right;
    background-size: 10px;
    padding-left: 0;
}

.product-list-wrap .breadcrumb {
    margin: 0;
}

.product-list-wrap .breadcrumb .breadcrumb-item a {
    color: #404040;
    font-weight: 500;
}

.product-list-wrap .breadcrumb .breadcrumb-item {
    font-size: 13px;
    font-weight: 800;
}

.product-list-filter>div {
    position: sticky;
    top: 80px;
    padding-top: 120px;
}

.product-category ul {
    list-style: none;
    padding: 0;
    overflow: hidden
}

.product-category ul .inner-sub-heading {
    display: inline-block;
    font-weight: bold;
    position: relative;
    font-size: 17px;
    text-transform: none;
    font-style: italic;
    color: #000;
}

.product-category ul .product-sub-category .bold {
    font-weight: bold
}

.product-category ul li:first-child {
    margin-top: 0px
}

.product-category ul li a {
    padding: 0 0 0px 0;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    font-style: normal;
    color: #262626;
    cursor: pointer;
}

.product-category>ul>li+li {
    margin-top: 20px;
}

.product-category ul li a:hover {
    text-decoration: underline;
}

.product-col,
.product-col-inner {
    position: relative;
    overflow: hidden;
}

.product-col .onsale {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    color: #262626;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    padding: 7px 15px;
    line-height: 1;
}

.product-col h3 {
    font-size: 12px;
    font-weight: 800;
    font-style: italic;
    color: #666;
    line-height: 1.1;
    min-height: 51px;
    padding: 14px 0 10px 0;
    margin: 0;
    max-width: 90%;
    position: relative;
}

.product-col a {
    cursor: pointer;
}

.product-col h3::after {
    content: "";
    display: block;
    width: 22px;
    height: 1px;
    border-bottom: solid 1px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.product-col .price {
    display: block;
    padding: 10px 0;
    color: #a3865b;
    font-weight: 600;
}

.product-col .price del {
    opacity: .5;
    font-weight: 400;
}

.product-col .product-overlay {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    transition: top 300ms;
    /* cursor: pointer; */
}

.product-col:hover .product-overlay {
    top: 0;
}

.product-col .product-overlay img[alt="bag"] {
    width: 35px;
    transition: all 300ms;
}

.product-col .product-overlay img[alt="bag"]:hover {
    width: 40px;
}

.product-col .product-overlay .add-wishlist a {
    color: #fff;
    font-size: 30px;
}

.product-col .product-overlay .add-wishlist a:hover {
    font-size: 35px;
}

.product-col .product-overlay .add-wishlist {
    padding: 5px 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #fff;
    margin: 10px 0;
}

.product-col .product-overlay a {
    color: #f2f2f2;
    font-size: 9px;
    line-height: 1.2;
    font-weight: bold;
    transition: all 300ms;
}

.product-col .product-overlay a:hover {
    font-size: 11px;
}

.product-list-filter h4 {
    padding-bottom: 15px;
    margin: 0;
    font-weight: bold;
    color: #666;
    font-size: 13px;
    text-transform: uppercase;
    font-style: normal;
}

.product-filter-color,
.product-filter-fabric,
.product-filter-tag {
    padding: 0;
    list-style: none;
}

.product-filter-tag li {
    display: inline-block;
    padding-right: 10px;
    line-height: 0.5;
    padding: 0 9px 0 0;
    margin: 0 6px 3px 0;
    border-right: 1px solid #a3865b;
}

.product-filter-color li a,
.product-filter-fabric li a,
.product-filter-tag li a {
    color: #a3865b;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    position: relative;
}

.product-filter-color li a {
    padding-left: 26px;
}

.product-filter-color li a span {
    display: block;
    height: 17px;
    width: 17px;
    border-radius: 30px;
    left: 0px;
    top: 1px;
    position: absolute;
    color: #666;
}

.product-filter-color .count,
.product-filter-fabric .count {
    font-size: 10px;
    position: relative;
    top: -1px;
    opacity: 0.5;
}

.product-list-filter .form-control[type="search"] {
    vertical-align: top;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 13px;
    border: none;
    border-bottom: solid 1px;
    border-color: #ccc;
    border-radius: 0px;
    background-position: right;
    background-size: 10px;
    padding-left: 0;
}

.product-list-filter .form-control[type="search"]::placeholder {
    font-style: italic;
}

.product-list-filter .product-list-search {
    position: relative;
}

.product-list-filter .product-list-search .btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 35px;
    padding-right: 0;
    line-height: 15px;
}


.product-list-filter .form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #404040;
    cursor: pointer;
    border-radius: 0;
}

.product-list-filter .form-range::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #404040;
    cursor: pointer;
    border-radius: 0;
}

.product-list-price-range .price_label {
    display: flex;
    color: #404040;
    font-size: 13px;
    justify-content: space-between;
    gap: 5px;
}

.product-list-price-range .price_label span {
    color: #a3865b;
}

.product-list-price-range .price_label .btn {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    color: #a3865b;
    padding: 0;
}

.product-list-price-range {
    margin-bottom: 25px;
}

.product-detail-info-wrap {
    max-width: 360px;
    margin-left: auto;
}

.product-dec-wrap {
    border: 1px solid #000;
    padding: 20px;
    max-width: 300px;
}

.product-dec-wrap .accordion-item {
    border-color: #000;
}

.product-dec-wrap .accordion-button {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.8px;
}

.product-dec-wrap .accordion-button::after {
    content: "+";
    background: none;
    text-align: center;
}

.product-dec-wrap .accordion-button:not(.collapsed)::after {
    content: "-";
    background: none;
}

.product-dec-wrap .accordion-body {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.3px;
}

.edit-outer {
    min-height: 100vh;
    background-color: #F1F1F1;
}

.top-heading {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    padding: 20px 0;
}

.top-heading h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #081732;
    margin: 0;
}

.edit-inner-left,
.edit-inner-right {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 2px 2px 10px 0px rgba(135, 160, 184, 0.16);
}

.edit-inner-left {
    padding: 20px;
}

.edit-outer h3 {
    color: #040C19;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 15px;
}

.edit-outer .form-label {
    color: #040C19;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-transform: capitalize;
}

.edit-outer .form-control,
.edit-outer .form-select,
.edit-outer .form-check-label {
    color: #363D47;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}

.edit-outer .add-more-list {
    color: #000;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
}

.edit-inner-right>div {
    padding: 20px;
}

.edit-inner-right>div+div {
    border-top: 1px solid #f5f5f5;
}

.form-check-input,
.form-check-label {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #a3865b;
    border-color: #a3865b;
}

.edit-outer .edit-btn-group {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.edit-outer .edit-btn-group {
    padding: 20px 0;
}

.edit-outer .edit-btn-group .btn {
    border: 2px solid;
    font-size: 14px;
    font-weight: 600;
}

.edit-outer .edit-btn-group .cancel,
.cancel {
    border-color: #db4a31;
    color: #db4a31;
}

.cancel:hover {
    border-color: #db4a31;
    color: #db4a31;
}

.edit-outer .edit-btn-group .save {
    background-color: #007f61;
    color: #fff;
    min-width: 100px;
}

.blog-detail-wrap {
    max-width: 920px;
    margin: 0 auto;
    padding: 60px 20px;
}

.blog-detail-wrap h1 {
    max-width: 920px;
    font-size: 42px;
    margin: 0 auto 15px;
    text-align: center;
    font-weight: 600;
}

.blog-detail-wrap .top-para {
    font-size: 24px;
    max-width: 800px;
    text-align: center;
    margin: 0 auto 20px;
}

.blog-detail {
    text-align: center;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}

.blog-detail a {
    color: #000;
    text-decoration: underline;
}

.blog-detail img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.blog-detail-wrap .first-para::first-letter {
    font-size: 406%;
    float: left;
    text-transform: uppercase;
    margin-right: 15px;
    margin-bottom: -4px;
    margin-top: 0;
    line-height: 60px;
}

.blog-detail-wrap .save-article,
.blog-detail-wrap .save-article:active {
    background-color: #000000;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 6.25rem;
    font-size: 0.70028rem;
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: 0.045rem;
    display: inline-flex;
    align-items: center;
}

.blog-detail-wrap .save-article svg {
    width: 20px;
    height: 25px;
    margin-right: 4px;
    vertical-align: middle;
}

.blog-detail-wrap h4 {
    font-size: 18px;
    font-weight: 700;
}

.other-post {
    padding: 50px 0;
    border-top: 1px solid #000;
}

.other-post h2 {
    text-align: center;
    font-size: 30px;
    text-transform: capitalize;
    margin-bottom: 40px;
}

.other-post-inner img {
    width: 100%;
}

.other-post a {
    color: #000;
}

.other-post-inner p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.12px;
    line-height: 22px;
    margin: 0;
    text-align: center;
    padding: 10px 0 0;
}

.user-dropdown .dropdown-menu {
    right: 0;
    left: auto;
    margin: 0;
}

.modal-header h2 {
    font-size: 16px;
    text-transform: capitalize;
}

#select-address .form-check {
    font-size: 13px;
    color: #000;
    padding-bottom: 10px;
}

#select-address .form-check-label span.name {
    font-weight: 600;
    display: block;
}

#select-address .form-check+.form-check {
    border-top: 1px dashed #eaeaec;
    padding-top: 10px;
}

#select-address .add-address,
#select-address .add-address:hover {
    width: 100%;
    padding: 10px 0;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;
    display: block;
    border: 1px solid #282c3f;
    color: #282c3f;
    text-align: center;
    text-transform: uppercase;
}

.edit-outer .save-address-as .form-check-label {
    padding: 4px 16px;
    border: 1px solid #282c3f;
    border-radius: 20px;
    font-size: 11px;
    min-width: 75px;
    text-align: center;
}

.save-address-as {
    display: flex;
    gap: 10px;
}

.edit-outer .save-address-as .form-check-input {
    position: absolute;
    left: 0;
    margin: 0;
    opacity: 0;
}

.edit-outer .save-address-as .form-check {
    padding: 0;
    position: relative;
}

.edit-outer .save-address-as .form-check-input:checked+.form-check-label {
    border: 1px solid #a3865b;
    color: #a3865b;
    font-weight: 700;
}

.search-order {
    position: relative;
    margin-bottom: 20px;
}

.search-order .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 2px solid #a3865b;
    color: #a3865b;
    font-size: 13px;
    font-weight: 600;
    height: 100%;
    line-height: 100%;
}

.search-order .form-control {
    height: 45px;
}

.all-order-wrap,
.all-order-list-inner {
    background-color: #F1F1F1;
}

.all-order-wrap {
    padding: 10px;
}

.all-order-list {
    background-color: #fff;
    padding: 10px;
    font-size: 13px;
}

.all-order-list+.all-order-list {
    margin-top: 10px;
}

.all-order-list .order-status {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    gap: 15px;
    margin-bottom: 10px;
}

.all-order-list .order-status .status.delivered {
    color: #2d852d;
}

.all-order-list .order-status .status.cancelled {
    color: #000;
}

.all-order-list .order-status .status.confirmed {
    color: #bfbf2d;
}

.all-order-list .order-status p {
    margin: 0;
}

.all-order-list .order-status .delivery-date {
    color: #000;
    font-size: 12px;
}

.all-order-list .product-list-thumbnail {
    display: flex;
    gap: 20px;
    font-size: 13px;
    padding: 10px;
}

.all-order-list .product-list-thumbnail>img {
    width: 75px;
}

.all-order-list .brand-name {
    font-weight: 700;
    margin-bottom: 5px;
}

.all-order-list .order-btns,
.all-order-list .order-footer {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
}

.all-order-list .order-footer {
    border-top: 1px solid #fff;
    padding: 10px;
    justify-content: space-between;
}

.all-order-list .order-btns .btn {
    border: 1px solid #000;
    font-size: 12px;
    flex-grow: 1;
    border-radius: 0;
    font-weight: 600;
    text-transform: uppercase;
    max-width: 250px;
}

.all-order-list .rating {
    text-align: center;
}

.all-order-list .rating>span {
    display: inline-block;
    margin-bottom: 5px;
}

.all-order-list .rating-inner {
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.all-order-list .rating .fa-solid {
    color: #a3865b;
}

.all-order-list .rating .fa-regular:hover {
    cursor: pointer;
    color: #a3865b;

}

.all-order-list .order-status i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    border-radius: 50%;
}

.all-order-list .order-status .fa-solid.fa-xmark {
    background-color: #ccc;
    color: #000;
}

.all-order-list .order-status .fa-solid.fa-truck {
    color: #fff;
    background-color: #2d852d;
}

.all-order-list .order-status .fa-solid.fa-bag-shopping {
    color: #fff;
    background-color: #bfbf2d;
}

.bulk-upload-file {
    position: relative;
    padding-right: 40px;
}

.bulk-upload-file a {
    position: absolute;
    right: 0;
    top: 2px;
    font-size: 22px;
    color: #000;
}

.blog-list {
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
    border-radius: 6px;
}

.blog-list-desc {
    padding: 10px;
}

.blog-list h4 {
    color: #040C19;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 0;
}

.blog-list .blog-status {
    color: #202020;
    font-size: 11px;
}

.blog-list p {
    color: #000;
    font-size: 14px;
    margin: 0;
}

.blog-list .blog-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;
    border-top: 1px solid #BEBEBE;
}

.blog-table {
    min-width: 700px;
}

.blog-list .blog-action a,
.blog-table a {
    font-size: 16px;
    color: #000;
}

.blog-list .blog-action .unpublish,
.blog-table .unpublish {
    font-size: 12px;
    font-weight: 700;
    border-radius: 0;
    color: #a3865b;
    text-decoration: underline;
}

.blog-table tr td {
    font-size: 14px;
}

.blog-list .blog-action .right-icon {
    display: flex;
    align-items: center;
    gap: 20px;
}

.thanku-wrap {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px 150px;
}

.thanku-wrap p {
    font-size: 18px;
    max-width: 800px;
    text-align: center;
    margin-bottom: 70px;
}

.table-responsive a {
    cursor: pointer;
}

.pagination-block {
    padding: 0 20px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.pagination-block .page-link:focus {
    box-shadow: none;
}

.customSelect {
    display: flex;
    align-items: center;
}


.customSelect .selectBox {
    width: auto;
    height: 30px;
    margin-left: 5px;
    position: relative;
    border: 1px solid #9c9c9c;
    display: flex;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.customSelect select {
    border: 0px !important;
    outline: none;
    scroll-behavior: smooth;
    position: relative;
    max-width: 100%;
    min-height: auto;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    cursor: pointer;
}

.dv-accnt-prdlst {
    margin-top: 50px;
}

.dv-td-img {
    width: 10%;
}

.dv-td-img img {
    border: 1px #a3865b solid;
    border-radius: 7px;
    height: 40px;
}

.dv-btn-cncl {
    border-color: #db4a31;
    color: #db4a31;
}

.dv-btn-cncl:hover {
    border-color: #db4a31;
    color: #995f56;
}

.dv-info-saved p {
    font-size: 20px;
    font-weight: 500;
}

.dv-info-saved i {
    font-size: 25px;
    color: #4caf50;
}

.dv-info-saved {
    display: flex;
}

.dv-mdl-cls {
    text-align: right;
}


.dv-qry-mdl label {
    font-weight: 600;
}

.sml-new-qry {
    color: red;
    font-weight: 700;
    animation: animate 1.5s linear infinite
}

@keyframes animate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}

.dv-flenme {
    display: flex;
}

.dv-flenme p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
}


.enter-otp-input {
    margin-bottom: 30px;
    margin-left: 170px;
}

.slct-ordr-stts {
    max-width: 100%;
    text-align: center;
}

.ordr-fttr {
    border-top: 1px solid #fff;
    padding: 10px;
}

.full-page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 18%);
    z-index: 4;
}

.full-page-loader img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.blg-edit-img img {
    max-width: 7%;
    border: #a3865b solid 1px;
    border-radius: 7px;
}

.blg-edit-img i {
    position: absolute;
    color: #F44336;
    cursor: pointer;
}

.arrow {
    box-sizing: border-box;
    height: 5vw;
    width: 5vw;
    border-style: solid;
    border-color: white;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
    transition: border-width 150ms ease-in-out;
}

.dv-ordr-usr {
    color: #9f8b6d;
    font-size: 12px;
    font-weight: 500;
}

.dv-ordr-info {
    color: #a3865b;
    font-size: 13px;
}

.dv-ordr-info span {
    text-decoration: underline;
    cursor: pointer;
}

.dv-ordr-info span:hover {
    font-weight: 800;
    color: #a27b42;
}

.dv-ordr-stats {
    width: 40%;
}

.dvcust {
    width: 30%;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dv-adrss-dtls label {
    font-weight: 500;
    font-size: 15px;
}

.dv-adrss-dtls p {
    font-weight: 500;
    font-size: 12px;
}
.para-unavail{text-align: center;}