
.react-confirm-alert .react-confirm-alert-body { text-align: center; }
    .react-confirm-alert .react-confirm-alert-body h1 { font-size: 2rem; }
.react-confirm-alert-button-group { display: inline-block !important; margin-top: 40px !important; }

    .react-confirm-alert-button-group button { background-color: #a3865b !Important; color: #fff !important; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 6px 0px; border-radius: 25px !important; text-transform: capitalize; display: inline-block !important; white-space: nowrap; min-width: 160px; padding: 7px 25px !important; font-weight: 600; font-size: 18px !important; transition: all 0.2s; text-align: center; }

    .react-confirm-alert-button-group button:not(:first-child) { background-color: #d7b889 !Important; }

    .react-confirm-alert-button-group button:hover{background-color: #70470a !important; transform: scale(1.02); }
.product-desc span:not(:empty):not(:last-child):after { content: ", "; }
.required:after { content: "*";color:red; }
.share-buttons button{
    width: auto; margin-right: 2%;
}
.selected-cat{font-weight: bold !important; text-decoration: underline;}
.thanku-wrap p { font-size: 1.5em; text-align: center;  }
.thanku-wrap  h2{ font-size: 5em; margin: 5% 0 1%;font-weight: 700; text-align: center;}
.thanku-wrap h2 span{ color:#10c328 ; margin-right:15px;}
.thanku-wrap h2 span.error{ color:#d03939 ; margin-right:15px;}
.thanku-wrap .orange-btn { margin-top:15px;}