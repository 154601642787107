@media (min-width: 1600px) {
    .container {
        max-width: 1570px;
    }
}

@media screen and (max-width: 1199px) {
    .latest-slider-inner h3 {
        font-size: 28px;
    }
    .all-order-list .order-footer{
        display: block;
    }
    .all-order-list .order-btns{
        margin-bottom: 10px;
    }
    .all-order-list .rating{
        text-align: left;
    }
    .blog-detail-wrap h1 {
        font-size: 38px;
    }
}

@media screen and (max-width: 991px) {
    .banner-content h1 {
        font-size: 42px;
        margin-bottom: 50px;
    }

    .latest-slider-inner h3 {
        font-size: 24px;
    }

    .login-details {
        display: block;
    }

    .login-details .logo-div {
        display: none;
    }

    .cart-row {
        flex-direction: column;
    }

    .product-list-filter>div{
        padding-top: 20px;
    }
    .dashboard-outer-inner{
        display: block;
    }
    .dashboard-outer-inner .left-fixed-menu{
        width: 100%;
    }
    .blog-detail-wrap h1 {
        font-size: 34px;
    }
}

@media screen and (max-width: 767px) {
    header {
        background: #fff;
        padding: 0;
        position: sticky;
        top: 0;
    }

    header .nav-link {
        color: #000;
        text-align: left;
    }

    header .navbar-collapse {
        order: 3;
        border-top: 1px solid #000;
    }

    header .right-btn {
        order: 2;
        margin-left: initial;
    }

    header .navbar {
        justify-content: space-between;
    }

    header .container-fluid {
        padding: 0;
    }

    header .navbar-toggler {
        position: relative;
        height: 58px;
        text-align: left;
        padding: 0 20px;
    }

    header .navbar-toggler::after {
        content: "";
        background: #000;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }


    header .navbar-brand .hover-mob-logo {
        display: block;
        margin-left: 10px;
    }

    header .navbar-brand .web-logo {
        display: none;
    }

    .banner-content h1 {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: initial;
    }

    .banner-content a {
        font-size: 16px;
        padding: 10px 50px 10px 20px;
    }

    .banner-content a::before {
        right: 15px;
        top: 22px;
    }

    .banner-content a::before {
        right: 15px;
        top: 22px;
    }

    .banner-content a::after {
        right: 15px;
        top: 18px;
    }

    .banner-content a:hover::before {
        width: 26px;
    }

    .banner-content a:hover::after,
    .banner-content a:hover::before {
        right: 12px;
    }

    .banner-content {
        top: 50%;
        transform: translate(-50%, -50%);
        bottom: auto;
    }

    .section,
    footer {
        padding: 40px 0;
    }

    section h2 {
        margin-bottom: 20px;
        font-size: 28px;
    }

    .brands-wrap ul {
        gap: 20px;
    }

    .brands-wrap ul li {
        width: 160px;
    }

    .latest-slider-inner h3 {
        font-size: 20px;
    }

    .contact-banner {
        padding: 100px 0;
        text-align: center;
    }

    .contact-banner h1 {
        font-size: 40px;
    }

    .about-banner {
        padding: 100px 0;
    }

    .about-banner h1 {
        font-size: 30px;
        line-height: 45px;
    }

    .feature .inner,
    .team .inner {
        padding: 20px;
    }

    .team .inner span {
        font-size: 52px;
    }

    .wishlist-wrap,
    .cart-section,
    .subscription-section.form-section {
        padding: 50px 0;
    }

    .sign-wrap{
        padding: 20px;
        border-radius: 20px;
    }
    .sign-details {
        padding: 20px;
    }
    .sign-wrap h2{
        font-size: 24px;
    }
    .sign-wrap .form-control {
        padding: 10px 20px;
        margin-bottom: 20px;
    }
    .member-links{
        padding: 0 20px;
    }

    .product-list-wrap h2{
        font-size: 30px;
        margin: -90px 0 0;
        padding: 40px 0 40px 32px;
    }
    .blog-detail-wrap h1,.other-post h2 {
        font-size: 28px;
    }
    .blog-detail-wrap .top-para{
        font-size: 20px;
    }
    .cart-items-list .wishlist-column{
        flex-direction: column;
    }
   
}

@media screen and (max-width: 575px) {
    .banner-content h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .banner-content a {
        font-size: 14px;
        padding: 7px 40px 7px 12px;
    }

    .banner-content a::before {
        right: 12px;
        top: 18px;
        width: 20px;
    }

    .banner-content a:hover::before {
        width: 22px;
    }

    .banner-content a:hover::after,
    .banner-content a:hover::before {
        right: 10px;
    }

    .banner-content a::after {
        right: 12px;
        top: 15px;
        width: 8px;
        height: 8px;
    }

    section h2 {
        font-size: 22px;
    }

    .category-wrap h3{
        margin-bottom: 15px;
    }
    .category-wrap h3,
    .other-post-inner p {
        font-size: 16px;
    }
    .blog-detail-wrap h1,.other-post h2 {
        font-size: 22px;
    }
    .blog-detail-wrap .top-para{
        font-size: 16px;
    }
    .blog-detail-wrap .desc {
        font-size: 14px;
    }
    .blog-detail-wrap, .other-post {
        padding: 40px 20px;
    }
    .other-post h2{
        margin-bottom: 20px;
    }
}